// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-assinatura-tsx": () => import("./../../../src/pages/assinatura.tsx" /* webpackChunkName: "component---src-pages-assinatura-tsx" */),
  "component---src-pages-consulta-receita-tsx": () => import("./../../../src/pages/consulta-receita.tsx" /* webpackChunkName: "component---src-pages-consulta-receita-tsx" */),
  "component---src-pages-download-receita-tsx": () => import("./../../../src/pages/download-receita.tsx" /* webpackChunkName: "component---src-pages-download-receita-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-termos-de-consentimento-para-tratamento-de-dados-pessoais-tsx": () => import("./../../../src/pages/termos-de-consentimento-para-tratamento-de-dados-pessoais.tsx" /* webpackChunkName: "component---src-pages-termos-de-consentimento-para-tratamento-de-dados-pessoais-tsx" */),
  "component---src-pages-welcome-farmaceutico-tsx": () => import("./../../../src/pages/welcome-farmaceutico.tsx" /* webpackChunkName: "component---src-pages-welcome-farmaceutico-tsx" */),
  "component---src-pages-welcome-medico-tsx": () => import("./../../../src/pages/welcome-medico.tsx" /* webpackChunkName: "component---src-pages-welcome-medico-tsx" */)
}

